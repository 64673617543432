













import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import ExerciseForm from '@/components/forms/exercise/ExerciseForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import { ExerciseLargeResource, ExerciseStore } from '@/store/types'
import { convertDateToMSK, convertHoursMinutesToSeconds } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: { ExerciseForm },
})
export default class BankNew extends Mixins(NotifyMixin) {
  private get breadcrumbs () {
    return [
      { name: 'Банк домашних заданий', path: '/manager/bank/exercises' },
    ]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handleSubmit (form: ExerciseStore) {
    const formSubmit = {
      ...form,
      avgExecutionSeconds: convertHoursMinutesToSeconds(form.avgExecutionSeconds as unknown as string),
      deadlineAt: form.deadlineAt ? convertDateToMSK(form.deadlineAt, this.isLocalTimezone) : form.deadlineAt,
      spendingAt: form.spendingAt ? convertDateToMSK(form.spendingAt, this.isLocalTimezone) : form.spendingAt,
    }

    ManagerExercisesModule.saveExercise({
      params: formSubmit,
    })
      .then((response: ExerciseLargeResource) => {
        this.notifySuccess('Основа задания сохранена')
        this.$router.push({
          name: 'manager.bank.exercises.item.edit',
          params: { exerciseUUID: response.uuid },
        })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание задания',
    }
  }
}
